.stopwatch {
    width: 250px;
    height: 90px;
    margin: 100px auto;
    background: white;
    color: #333;
    border-radius: 10px;
    padding: 60px 50px 100px;
    text-align: center;
    font-size: 20px;
}

.stopwatch button {
    font-size: 22px;
    -webkit-appearance: none;
    border: none;
    background-color: #2e80b3;
    color: white;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    transition: .3s;
    margin: 5px;
}

.stopwatch button:last-child {
    background-color: #ae7617;
}

.stopwatch button:hover {
    background: #333;
    color: #fff;
    cursor: pointer;
}